import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import UsersTable from './UsersTable'

const UsersListData = ({ filter, onFiltered, selectedUsers, setSelectedUsers, data, disableLastActive }) => {
	const usersList = useMemo(() => {
		const currentDate = moment()
		const setLocked = progress => {
			console.log(progress)
			//if (progress > 0) {
			//	return 'Locked'
			//}
			return ''
		}
		return _.map(data && data.subjectsActivityList.data.data, user => {
			user.name = `${user.first_name} ${user.last_name}`
			user.subjectName = user.subject.name
			user.signed_off = user.subject.date_completed
				? moment(user.subject.date_completed).format('DD/MM/YYYY')
				: setLocked(user.subject.progress)
			user.due = user.subject.due
			user.last_active = user.subject.lastActive
				? currentDate.diff(user.subject.lastActive.created_at, 'days')
				: null
			user.progress = user.subject.progress
			user.learning_progress = user.subject.learning_progress ? user.subject.learning_progress : null
			user.observation_progress = user.subject.observation_progress ? user.subject.observation_progress : null
			user.retake = user.subject.retake ? user.subject.retake : null

			return user
		})
	}, [data])

	const setPage = useCallback(
		page => {
			onFiltered({ ...filter, page })
		},
		[onFiltered, filter],
	)

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)

			const newActiveParam = { name: filter.sort_param, order: filter.sort_direction }

			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFiltered({ ...filter, sort_param: newActiveParam.name, sort_direction: newActiveParam.order })
		},
		[filter, onFiltered],
	)
	return (
		<>
			<div className="users-list">
				<UsersTable
					usersList={usersList}
					page={filter.page}
					setPage={setPage}
					perPage={data.subjectsActivityList.data.per_page}
					totalCount={data.subjectsActivityList.total_count}
					length={data.subjectsActivityList.data.total}
					handleSortTable={handleSortTable}
					selectedUsers={selectedUsers}
					setSelectedUsers={setSelectedUsers}
					disableLastActive={disableLastActive}
					activeSortParam={{
						name: filter.sort_param || '',
						order: filter.sort_direction || 'asc',
					}}
				/>
			</div>
		</>
	)
}

UsersListData.propTypes = {
	filter: PropTypes.object.isRequired,
	onFiltered: PropTypes.func.isRequired,
	selectedUsers: PropTypes.array.isRequired,
	setSelectedUsers: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	disableLastActive: PropTypes.bool,
}

UsersListData.defaultProps = {
	disableLastActive: false,
}

export default memo(UsersListData)
